.company-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 4rem;
    background-color: #fff;
  }
  
  .company-content {
    max-width: 50%;
  }
  
  .company-heading {
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 1rem;
  }
  
  .company-title {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #00bfa5;
  }
  
  .company-description {
    font-size: 16px;
    color: black;
    line-height: 1.5;
    margin-bottom: 2rem;
    text-align: justify;
  }
  
  .signup-button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #00bfa5;
    color: #fff;
    border-radius: 22px;
    text-decoration: none;
    font-weight: bold;
    font-size: 3rem;
    margin-left: 12rem;
    margin-top: 2rem;
  }
  
  .company-image img {
    max-width: 100%;
    border-radius: 60%;
    object-fit: cover; /* Ensures the image scales properly within the circular frame */
    width: 400px; /* Set a width for the round image */
    height: 400px; /* Set a height to make it a circle */
    margin-left: 20rem;
  }
  .company-images img {
    max-width: 70%;
    border-radius: 20%;
    object-fit: cover; /* Ensures the image scales properly within the circular frame */
    width: 300px; /* Set a width for the round image */
    height: 300px; /* Set a height to make it a circle */
    margin-left: 20rem;
  }
  .company-future-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;
    padding: 4rem 0;
    background-color: #f9f9f9;
    border-radius: 2rem;
    padding: 1rem 4rem;
    max-width: 120%;
  }

  
  .quote-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .quote-circle {
    background-color:#00bfa5;
    border-radius: 10%;
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1.5rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-left: 16rem; 
  }
  
  .quote-text {
    font-size: 24px;
    color: white;
    font-weight: bold;
    
    font-style: italic;
    margin-bottom: 2rem;
  }
  
  .quote-author {
    margin-top: 8rem;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-right: 6rem;
  }
  
  .future-content {
    flex: 2;
    margin-left: 3rem;
  }
  
  .future-title {
    font-size: 2rem;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 1rem;
  }
  
  .future-description {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
    text-align: justify;
  }
  @media only screen and (max-width: 768px){
    .signup-button{
      display: inline-block;
      padding: 3px 1.5rem;
      background-color: #00bfa5;
      color: #fff;
      border-radius: 22px;
      text-decoration: none;
      font-weight: 500;
      font-size: 2rem;
      margin-left: 1rem;
      margin-top: 2rem;
    }
    .company-image img{
      max-width: 100%;
      border-radius: 10%;
      object-fit: cover;
      width: 400px;
      height: 400px;
      margin-top: 2rem;
      margin-left: -1rem;
    }
    .company-images img{
      max-width: 100%;
      border-radius: 10%;
      object-fit: cover;
      width: 400px;
      height: 400px;
      margin-top: 2rem;
      margin-left: 0rem;
    }
    .company-description{
      line-height: 1.5;
      text-align: justify;
    }
    .quote-circle{
      margin-left: -1rem;
    }
    .future-description{
      text-align: justify;
    }
  }