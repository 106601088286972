.listingdetail {
    padding-bottom: 4rem;

    @media only screen and (max-width: 768px) {
        padding: 0 1rem;
    }
    .listingdetail__content {
        display: flex;
        gap: 20px;
        margin: 40px 0;
        margin-left: 6rem;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            margin-left: 0;
        }
    }
    
    .listingdetail__media {
        flex: 2;

        @media only screen and (max-width: 768px) {
            overflow-x: auto;
            white-space: nowrap;
            padding-bottom: 1rem;
        }
    }
    
    .listingdetail__payment {
        flex: 1;
        background-color: #f9f9f9;
        padding: 20px;
        border-radius: 5px;

        @media only screen and (max-width: 768px) {
            margin-top: 2rem;
        }
    }
    .listingdetail__payment-title {
        color: #2fa18a; /* Example color */
        font-size: 40px;
        font-weight: bold;
        margin-left: 0;
        margin-bottom: 20px;
        text-align: center;
        /* You can add more styling as needed */
    }
    .payment-form label{
        font-size: 16px;
        margin-right: 1rem;

    }
    .payment-form select{
        
        margin-left: 3rem;
        width: 54%;
        height: 45px;

    }
    .amount  label{
        font-size: 16px;
        margin-left: 1rem;

    }
    .amount input{
        margin-left: 7rem;
        margin-top: 3rem;
        width: 54%;
        height: 45px;
    }
    .payment-btn{
        margin-top: 6rem;
        margin-left: 12rem;
        padding: 2rem;
        font-size: 16px;
        font-weight: bold;
        background-color: #2fa18a;
        border: none;
        border-radius: 23px;

    }
    
    

    &__header {
        background-color: rgba($color-primary, 0.45);
        padding-top: 4rem;
        padding-bottom: 4rem;
        text-align: center;
    }

    &__title {
        font-size: 4.2rem;
        color: $color-white;
        margin-bottom: 2rem;
        font-weight: 200;
    }

    &__location {
        font-size: 2.4rem;
        color: $color-white;
        font-weight: 200;
    }

    &__breadcrumb {
        background-color: $color-grey-light;
        padding: 2rem 4rem;
        font-size: 1.6rem;
        margin-top: 2rem;
        margin-left: 2rem;


        &__link {
            color: $color-primary;
            text-decoration: none;
            

            &:hover {
                color: $color-tertiary;
            }
        }
    }

    &__display {
        width: 100%;
        text-align: center;

        @media only screen and (max-width: 768px) {
            width: auto;
            display: inline-block;
            margin-right: 1rem;
        }

        &__image {
            width: 100%;
            max-height: 600px;
            object-fit: cover;
            border-radius: 10px;
            margin-bottom: 1rem;

            @media only screen and (max-width: 768px) {
                width: auto;
                height: 200px;
            }
        }
    }
    .listingdetail__thumbnails__thumbnail__video {
        width: 100%;
        height: 75px;
        cursor: pointer;
        border-radius: 0.5rem;
    }
    
    .listingdetail__display__video {
        width: 100%;
        border-radius: 1rem;
    }
    

    &__thumbnails {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        overflow-x: auto;
        white-space: nowrap;

        @media only screen and (max-width: 768px) {
            justify-content: flex-start;
            margin-top: 1rem;
        }

        &__thumbnail {
            display: inline-block;
            margin-right: 1rem;



            &__image {
                width: 100px;
                height: 75px;
                object-fit: cover;
                cursor: pointer;
                border-radius: 5px;
                transition: transform 0.2s;

                @media only screen and (max-width: 768px) {
                    width: 100px;
                    height: 60px;
                }

                &:hover {
                    transform: scale(1.1);
                    border: 2px solid $color-primary;
                }
            }
        }
    }

    &__realtor {
        font-size: 2.4rem;
        color: $color-primary;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    &__contact {
        font-size: 1.6rem;
        color: $color-tertiary;
    }

    &__about {
        font-size: 1.6rem;
        margin-top: 1rem;
    }

    &__list {
        list-style: none;
        font-size: 1.6rem;
        background-color: #efefef;
        color: $color-black;
        border-radius: 25px;
        padding: 20px;
        margin-top: 2;

        &__item {
            margin-bottom: 1rem;
        }
    }

    &__description {
        font-size: 1.6rem;
        background-color: #efefef;
        color: $color-black;
        border-radius: 25px;
        padding: 20px;
        margin-top: 2;
    }
   

}