// Colors
$color-primary: #328da8;
$color-secondary: #47acad;
$color-tertiary: #2fa18a;
$color-quaternary: #87369e;

$color-grey-light: #efefef;
$color-grey-dark: #333;

$color-hover: #edf5f8;
$color-link-hover: #ccc;

$color-paragraph: #4a4a4a;

$color-green: #34eb49;

$color-white: #fff;
$color-black: #000;

// Font
$default-font-size: 1.6rem;
$heading-font-size: 2.4rem;
$paragraph-font-size: 1.6rem;

// Spacing
$spacing-small: 0.8rem;
$spacing-medium: 1.6rem;
$spacing-large: 2.4rem;
$spacing-xlarge: 4.8rem;

// Border Radius
$border-radius-small: 0.4rem;
$border-radius-medium: 0.8rem;
$border-radius-large: 1.6rem;

// Grid
$grid-width: 114rem;
$gutter-vertical: 4rem;
$gutter-vertical-small: 2rem;
$gutter-horizontal: 4rem;

$color-primary: #2c3e50;
$color-primary-light: #ecf0f1;
$color-secondary: #8e44ad;
$color-tertiary: #3498db;
$color-paragraph: #2d3436;
$color-white: #ffffff;