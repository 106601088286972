@import 'abstracts/mixins';
@import 'abstracts/variables';

@import 'base/base';
@import 'base/utilities';

@import 'components/alert';
@import 'components/auth';
@import 'components/card';
@import 'components/listingform';
@import 'components/navbar';
@import 'components/notfound';
@import 'components/pagination';

@import 'layout/grid';

@import 'pages/about';
@import 'pages/contact';
@import 'pages/home';
@import 'pages/listings';
@import 'pages/listingdetail';
@import "~@fortawesome/fontawesome-free/css/all.min.css";

@import 'pages/about';
@import 'pages/company';
// src/sass/main.scss

body, html {
    overflow-x: hidden; // Prevent horizontal scrolling
}



