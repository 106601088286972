// Global Reset and Box-Sizing
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

// HTML Element: Set base font size and responsive typography
html {
    font-size: 62.5%; // 1rem = 10px

    @include respond(tab-land) {
        font-size: 56.25%; // 1rem = 9px
    }

    @include respond(tab-port) {
        font-size: 50%; // 1rem = 8px
    }

    @include respond(phone) {
        font-size: 80%; // 1rem = 8px
    }
}

// Body Element: Set global styles and responsive padding
body {
    box-sizing: border-box;
    font-family: 'Bebas Neue', sans-serif; // Ensure font is loaded and has fallbacks

    @include respond(tab-port) {
        padding: 0;
    }
}

// Text Selection: Change background color of selected text
::selection {
    background-color: rgba($color-primary, 0.3);
}

// Accessibility: Improve focus visibility for better accessibility
:focus {
    outline: 2px dashed $color-primary;
    outline-offset: 4px;
}
