.home {
    &__form {
        margin-top: 4rem;
        margin-bottom: 6rem;
    }

    &__listings {
        margin-bottom: 4rem;
    }

    &__pagination {
        margin-bottom: 6rem;
    }
}