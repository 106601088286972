.navbar {
    width: 100%;
    padding: unset !important;

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 6rem;
        background-color:  #121212;
     

        &__logo {
            margin-left: 20px;
         

            &__link {
                font-size: 2.8rem;
                color: $color-white;
                text-decoration: none;
                
                
            }
            .navbar__logo__image {
                width: 120px; /* Adjust the width to your desired size */
                height: auto; /* Maintain aspect ratio */
                display: block;
                margin-top: 2rem;
                z-index: 10;
                position: relative;
            }
        }

        &__auth {
            margin-right: 4rem;

            &__link {
                font-size: 1.8rem;
                color: $color-white;
                text-decoration: none;
                
                

                &:first-of-type {
                    margin-right: 8rem;
                }
            }
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 6rem;
        background-color:  #121212;

        &__item {
            list-style: none;

            &__link {
                font-size: 1.8rem;
                color: $color-white;
                text-decoration: none;
                margin-left: 4rem;

                &:hover {
                    color: $color-quaternary;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {

        .navbar__logo__image {
            width: 80px;
            margin-bottom: 4rem; 
           
        }
        .navbar__bottom{
            margin-top: -1rem;
        }
    }


}