    // src/sass/pages/_about.scss

    @import '../abstracts/variables';

    .about {
        
        
        // @media only screen and (max-width: 768px){
        //     .about__display__image{
        //         border-radius: 20px;
        //         max-width: 100%;
        //         padding-right: 10px;
        //         height: 300px;
        //         margin-bottom: 2rem;
        //         margin-top: -45rem;
                
                
        //     }
        //     .about__stats{
        //         display: flex;
        //         justify-content: space-around;
        //         margin-top: 100rem
        //     }
        //     .about__content{
        //         height: 376px;
        //         margin-top: 69rem;
        //     }
        //     .about__info{
        //             padding: 10rem 0;
                    

        //     }
        //     // .about .stat {
                
        //     //     margin-bottom: 164rem;
        //     // }
        //     // .about .stat__number {
        //     //     font-size: 2.6rem;
        //     //     font-weight: bold;
        //     //     color: #ffffff;
        //     //     margin-bottom: 161rem;
        //     // }

        //     .about_subheading{
        //         font-size: 49px;
        //         margin-bottom: -24rem;
        //         margin-top: 219px;


        //     }
        //     .about__reasons{
        //         width: 100%;
        //         display: flex;
        //         flex-wrap: wrap;

        //     }
        


        // }
        // border-radius: 68px;
        
        @media only screen and (max-width: 768px) {
            &__info {
              flex-direction: column;
              padding: 2rem 0;
            }            
            &__content {
              max-width: 100%;
              padding-right: 0;
              height: auto;
              margin-top: 2rem;
            }            
            &__subheading {
              font-size: 3rem;
              margin-bottom: 2rem;
              
            }            
            .about__paragraph {
              font-size: 1.8rem;
              margin-bottom: 2rem;
              text-align: center;
              margin-top: -33px;
            }            
            &__stats {
              flex-wrap: wrap;
              margin-top: 2rem;
              order: 2;
            }            
            .stat {
              flex-basis: 50%;
              margin-bottom: 1rem;
            }            
            .about__display {
              max-width: 100%;
              margin-top: 2rem;
              order: 1;              
              &__image {
                width: 100%;
                height: auto;
                max-height: 300px;
                margin-right: 0;
                border-radius: 68px;
                object-fit: cover;              }
            }               
            .about__values {
              padding: 2rem;              
                &__image-container {
                    flex-direction: column;
                    align-items: center;
                }              
                &__video {
                    width: 100%;
                    margin: 5rem;
                    max-width: 680px;
                    height: auto;
                    border-radius: 1rem;
                    margin-top: 6rem;              }              &__content {
                    margin-left: 0;
                    padding-left: 0;
                }              
                &__main {
                    font-size: 3rem;
                    margin-left: 25rem;
                }             
                &__number {
                    font-size: 2.6rem;
                    margin-left: 27rem;
                }             
                .about__values__title {
                    font-size: 3rem;
                    color: #009688;
                    font-weight: 500;
                    margin-left: 27rem;
                }              
                .about__values__text {
                    font-size: 16px;
                    margin-left: 27rem;
                    text-align: justify;
                    padding: 0;                
                    letter-spacing: 1px !important; /* Reset letter spacing to default */
                    word-spacing: 1px !important;
                }
            }
            .footer {
                padding: 2rem 1rem;
                
                &__section, &__contact {
                  margin-left: 0;
                  margin-right: 0;
                  align-items: center;
                  text-align: center;
                  margin-top: 0;
            
                  h3 {
                    font-size: 2.2rem;
                    margin-bottom: 2rem;
                    margin-left: 0;
                  }
            
                  ul {
                    li {
                      margin-left: 0;
                      margin-bottom: 1.5rem;
            
                      a {
                        justify-content: center;
                        font-size: 1.4rem;
            
                        svg {
                          margin-right: 0.5rem;
                        }
                      }
                    }
                  }
            
                  p {
                    font-size: 1.4rem;
                    justify-content: center;
                  }
                }
            
                &__contact {
                  margin-top: 3rem;
                }
            
                &__bottom {
                  margin-top: 2rem;
            
                  p {
                    font-size: 1.2rem;
                  }
                }
            }
        }
            

        

        .row{
            max-width: unset;
            margin-bottom: unset;
        }
        

        
        &__info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3rem 0;
            background-color: #121212;
            color: #fff;
        }

        &__content {
            flex: 1;
            max-width: 50%;
            padding-right: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 500px;
        }

        &__subheading {
            font-size: 6rem;
            font-weight: 700;
            margin-bottom: 10rem;
            line-height: 1.2;
            text-align: center;
            margin-top: 0;
        }

        &__paragraph {
            font-size: 2.4rem;
            color: #ccc;
            margin-bottom: 12rem;
            line-height: 1;
            text-align: center;
            margin-top: 0;
        }

        &__button {
            display: inline-block;
            padding: 1.8rem 1.5rem;
            font-size: 2.0rem;
            color: #fff;
            background-color: #00bfa5;
            border: none;
            border-radius: 5px;
            text-decoration: none;
            margin: 1.5rem auto;
            transition: background-color 0.3s ease;
            cursor: pointer;
            justify-items: center;
            width: fit-content;

            &:hover {
                background-color: #009688;
            }

            .arrow {
                margin-left: 0.5rem;
            }
        }

        &__stats {
            display: flex;
            justify-content: space-around;
            margin-top: 2;
        }

        .stat {
            text-align: center;
            flex: 1;
            &__number {
                font-size: 2.6rem;
                font-weight: bold;
                color:  $color-white;
            }
            &__label {
                font-size: 1.6rem;
                font-weight: bold;
                color: $color-green;
                margin-top: 0.5rem;
            }
        }

        &__display {
            flex-shrink: 0;
            max-width: 50%;

            &__image {
                width: 80%;
                float: right;
                margin-right: 4rem;
                border-radius: 1rem;
                height: 650px;
                border-top-left-radius: 50%;
                border-top-right-radius: 50%;
            }
           
        }
        // @media only screen and (max-width: 768px) {
        //     flex-direction: column;
        
        //     .about__content,
        //     .about__display {
        //       max-width: 100%;
        //       padding-right: 0;
        //       margin-bottom: 2rem;
        //     }
        
        //     .about__display__image {
        //       width: 100%;
        //       margin-right: 0;
        //     }
          
        // }

        &__values {
            position: relative;
            display: flex;
            justify-content: flex-end;
            padding: 4rem;
            background-color: #2d2d2d; // Background color of the section
            
            &__image-container {
                display: flex;
                justify-content: flex-start;  /* Changed to flex-start to align items to the left */
                align-items: flex-start;
                margin-top: 20px;
            }

            &__video {
                width: 200%;
                max-width: 680px; // Set the image width to a desired size
                height: auto;
                border-radius: 1rem;
                margin-right: 35rem; // Adjust border radius as needed
            } 
            
            .about__values__content {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding-left: 20px; /* Add a smaller padding to bring it closer to the video */
                margin-left: -28rem; 
            
            }
            
            .about__values__item {
                margin-bottom: 20px;
                
            }
            .about__values__main{
                font-size: 4.6rem; /* Adjust to your preference */
                color: #009688; /* Use your preferred color */
                margin-bottom: 10px;
                text-align: center;
              

            }
            
            .about__values__number {
                font-size: 3.6rem; /* Adjust to your preference */
                color: $color-green; /* Use your preferred color */
                margin-bottom: 10px;
            }
            
            .about__values__title {
                font-size: 2rem;
                color: #009688; /* Adjust to your preference */
                font-weight: 200;
            }
            
            .about__values__text {
                font-size: 1.2rem;
                color: $color-white; /* Adjust to your preference */
                font-weight: 200;
            }
        
        }
        
        // @media only screen and (max-width: 768px) {
        //     flex-direction: column;
        //     align-items: center;
        
        //     &__image-container,
        //     &__content {
        //       max-width: 100%;
        //       margin: 0;
        //       padding: 0;
        //     }
        
        //     video {
        //       margin-bottom: 2rem;
        //     }
          
        // }

        // @media only screen and (max-width: 768px) {
        //     .about__values {
        //         flex-direction: wrap; /* Stack items vertically */
        //         align-items: center;
    
        //         &__image-container,
        //         &__content {
        //             max-width: 100%;
        //             margin: 0;
        //             padding: 0;
        //         }
    
        //         &__video {
        //             margin-bottom: 2rem; /* Ensure margin below video on small screens */
        //             max-width: 110%;
        //             margin-left: -1.3rem;
        //         }
        //         &__content {
        //             margin-top: 2rem; /* Ensure the content moves below the video */
        //         }
        //     }
    
        //     .about__content,
        //     .about__display {
        //         max-width: 100%;
        //         padding-right: 0;
        //         margin-bottom: 2rem;
        //     }
    
        //     .about__display__image {
        //         width: 100%;
        //         margin-right: 0;
        //     }
        // }

        &__reasons {
            padding: 4rem 0;
            background-color: #efefef;
            color: #333;

            &__heading {
                font-size: 5rem;
                font-weight: 800;
                text-align: center;
                margin-bottom: 4rem;
                color: #2fa18a;
            }

            .card {
                background: #fff;
                border-radius: 0.5rem;
                padding: 4rem;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                text-align: center;

                h3 {
                    font-size: 2rem;
                    margin-bottom: 1rem;
                    color: $color-primary;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    svg {
                        margin-right: 0.5rem; /* Adjust icon spacing */
                        color: $color-green;
                    }
                }

                p {
                    font-size: 1.6rem;
                    color: #666;
                }
            }
        }
    }

    .faq-section {
        padding: 2rem;
        background-color: $color-white; // Change this to match the background color of your footer
        border-radius: 0.5rem;
        max-width: 800px;
        margin: 0 auto;
    }

    .faq-heading {
        font-size: 4rem;
        font-weight: 800;
        text-align: center;
        margin-bottom: 4rem;
        color: #2fa18a;
    }

    .faq-item {
        background-color: #aa7b2a;
        border: 2px solid #2fa18a;
        border-radius: 0.5rem;
        margin-bottom: 1rem;
        overflow: hidden;

        &.open {
            .faq-question {
                background-color: #2fa18a;
            }
        }

        .faq-question {
            padding: 1rem;
            cursor: pointer;
            font-size: 1.8rem;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $color-black;
            background-color: $color-white;
        }

        .faq-answer {
            padding: 1rem;
            font-size: 1.6rem;
            color: $color-black;
            background-color: $color-white;
            border-top: 1px solid #7a5520;
        }

        .faq-icon {
            font-size: 2rem;
            color: #ffffff;
        }
    }

    .footer {
        background-color: #121212;
        color: #fff;
        padding: 2rem 0;
        border-top-right-radius: 1%;
        border-bottom-left-radius: 1%;
        border-top-left-radius: 1%;
        border-bottom-right-radius: 1%;
        width: 100%;
        height: 20%;

        &__section {
            display: flex;
            flex-direction: column;
            align-items: left;
            margin-left: 5rem;

            h3 {
                font-size: 2.8rem;
                margin-bottom: 3rem;
                margin-left: -1rem;
            }

            ul {
                list-style: none;
                padding: 0;

                li {
                    margin-bottom: 1rem;
                    margin-left: 2rem;

                    a {
                        color: #fff;
                        text-decoration: none;
                        font-size: 1.6rem;
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 1rem; /* Adjust icon spacing */
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            p {
                font-size: 1.6rem;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 0.5rem; /* Adjust icon spacing */
                }
            }
        }

        &__contact {
            display: flex;
            flex-direction: column;
            align-items: right;
            margin-left: 60rem;
            margin-top: -18rem;

            h3 {
                font-size: 2.8rem;
                margin-bottom: 3rem;
                margin-left: 2rem;
            }

            ul {
                list-style: none;
                padding: 0;

                li {
                    margin-bottom: 1rem;
                    margin-left: 2rem;

                    a {
                        color: #fff;
                        text-decoration: none;
                        font-size: 1.6rem;
                        display: flex;
                        align-items: center;

                        svg {
                            margin-right: 0.5rem; /* Adjust icon spacing */
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            p {
                font-size: 1.6rem;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 0.5rem; /* Adjust icon spacing */
                }
            }
        }

        &__bottom {
            text-align: center;
            margin-top: 3rem;

            p {
                font-size: 1.4rem;
            }
        }
    }
    
